import React from "react";
// import Banner from "./components/banner/banner";
import NewBanner from './components/banner/NewBanner'
import ConnectWallet from "./components/connectWallet/connect";

function App() {
  return (
    <>
      
      <NewBanner/>
    </>
  );
}

export default App;
