import { React, useEffect, useState } from "react";
import { Image, Modal, Button } from "react-bootstrap";
import { CheckMetaMaskInstalled, MetamaskConnection } from "../../redux/connectWallet/action";
import { useDispatch, useSelector } from "react-redux";
import {
  getLatestData,
  getLatestStage,
} from "../../redux/connectWallet/action";
import { toast } from "react-toastify";
import { ethers } from "ethers";
import Clouds from "../../assets/images/Clouds/cloud.png";
import backgroundVideo from "../../assets/images/background.mp4";
import Sun from "../../assets/images/sun.png";
import Stairs from "../../assets/images/stairs.png";
import Nftfram from "../../assets/images/nft-fram.png";
import Tile12 from "../../assets/images/tile12.png";
import Tile11 from "../../assets/images/tile11.png";
import LeftIcon from "../../assets/images/left-arrow.png";
import RightIcon from "../../assets/images/right-arrow.png";
import TopIcon from "../../assets/images/top-arrow.png";
import BottomIcon from "../../assets/images/bottom-arrow.png";
import ConnectWallet from "../connectWallet/connect";
import Loader from "../Loader/loader";
import Timer from "./time";
import axios from "axios";
import SwitchSideModal from "../Modals/switchSideModal";
import CongratulationModal from "../Modals/congratulationModel";
import NftListModal from "../Modals/nftListModal";
import PlayerListModal from "../Modals/playersListModal";
import RestartGameModal from "../Modals/restartGameModal";
import MyNftModal from "../Modals/myNftModal";
import NftCardModal from "../Modals/nftCardModal";
import CountdownModal from "../Modals/countDownModal";
import RemainingNftModal from "../Modals/remainingNftModal";
import GameDataModal from "../Modals/gameDataModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleUp,
  faArrowTurnUp,
} from "@fortawesome/free-solid-svg-icons";
import { LOADER_STATE_TRUE, LOADER_STATE_FALSE } from "../../redux/actionType";

const Banner = (props) => {
  let dispatch = useDispatch();
  const contractData = useSelector((state) => state.contract);

  const [show, setShow] = useState(false);
  const [myNftModal, setmyNftModalShoww] = useState(false);
  const [congratulationShow, setCongratulationShow] = useState(false);
  const [winnerReward, setWinnerReward] = useState(0);
  const [claimAmount, setClaimAmount] = useState(0);
  const [nftListShow, setNftListShow] = useState(false);
  const [nftCardModal, setNftCardModal] = useState(false);
  const [LeftMove, SetLeftMove] = useState(false);
  const [RightMove, SetRightMove] = useState(false);
  const [pushBackState, setpushBackState] = useState(false);
  const [pushBackdisabled, setpushBackdisabled] = useState(false);
  const [userStage, setuserStage] = useState(0);
  const [StimeoutTrue, setStimeoutTrue] = useState(false);
  const [stage, setstage] = useState(contractData.globalStage);
  const [tilenumber, settilenumber] = useState(1);
  const [stages, setstages] = useState(0);
  const [stair, setstair] = useState(0);
  const [sstages, setsstages] = useState(0);
  const [current, setcurrent] = useState(0);
  const [loader, setLoader] = useState(false);
  const [safeSides, setSafeSide] = useState([]);
  const [stop, setstop] = useState(false);
  const [usersNft, setusersNft] = useState([]);
  const [userNft, setUserNft] = useState([]);
  const [leftRightModal, setleftRightModal] = useState(false);
  const [countedArray, setCountedArray] = useState([]);
  const [allNftList, setAllNftList] = useState([]);
  const [claimDisable, setclaimDisable] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [myNfts, setMyNfts] = useState([]);
  const [gameDataModal, setGameDataModal] = useState(false);
  const [restartGameModal, setRestartGameModal] = useState(false);
  const [switchSideModal, setSwitchSideModal] = useState(false);
  const [clickedNft, setClickedNft] = useState([]);
  const [singlePlayerId, setsinglePlayerId] = useState("");
  const [claim, setClaim] = useState(true);
  const [gameEndModalHandle, setGameEndModalHandle] = useState(false);
  const [isZeroNfts,setIsZeroNfts] = useState(false);

  useEffect(() => {
    dispatch(CheckMetaMaskInstalled());
  }, []);

  // useEffect(() => {
  //   if(contractData.contractInstance){
  //     dispatch(getLatestStage(contractData.contractInstance))
  //   }

  // }, [contractData.contractInstance])
  useEffect(() => {
    if (contractData.countdownLoader == false) {
      setstages(contractData.globalStage);
    }
  }, [contractData.globalStage, contractData.countdownLoader]);

  useEffect(() => {
    if (contractData.nftData) {
      availableNFts();
    }
  }, [contractData.nftData, contractData.nftIds]);

  useEffect(() => {
    safeTilesForGame();
  }, [contractData?.safetiles]);

  useEffect(() => {
    if (contractData.contractInstance) {
      getPushBackAdvantage();
    }
  }, [contractData.contractInstance]);

  useEffect(() => {
    setstages(stage);
    if (
      contractData.startGameTime !== 0 &&
      contractData.gameInitializeDay > 0 &&
      contractData.isDynamicEnd == false
    ) {
      setstair(13);
    } else {
      setstair(stage);
    }
  }, [stage]);

  useEffect(() => {
    setcurrent(contractData.globalStage);
  }, [contractData.globalStage]);

  useEffect(() => {
    userDataForStage();
  }, []);

  useEffect(() => {
    userDataForStage();
  }, [contractData.stagesUserData, contractData.globalStage]);
  
  useEffect(() => {
    window.onload = () => {
      isConnected();
   };

    async function isConnected() {
       const accounts = await window.ethereum.request({method: 'eth_accounts'});       
       if (accounts.length) {
          console.log(`You're connected to: ${accounts[0]}`);
          // setConnectionCheck(true)
          dispatch(MetamaskConnection())
        } 
        // else {
          // const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
          // await provider.send("eth_requestAccounts", []);

          // toast.error("Metamask is not connected");
          // toast.info("Please Connect Your MEtamasl")
          // setConnectionCheck(false);
      //  }
    }
  }, [])


  // const getLatestStage = async () => {
  //   let provider = new ethers.providers.JsonRpcProvider(
  //     'https://rpc.ankr.com/avalanche_fuji');
  //   let contractInstance = new ethers.Contract(process.env.REACT_APP_CONTRACT_ADDRESS, contractAbi, provider);

  //   let gameStatusGlobalData = await contractInstance.gameStatusInitialized(1);
  //   let globalStage = Number(gameStatusGlobalData.stageNumber);
  //   const data = await contractInstance.getAll();
  //   let randomNumber = data[globalStage !== 0 ? globalStage - 1 : globalStage ]?._hex;
  //   if (contractData.startGameTime !== 0 || contractData.gameInitializeDay > 0) {
  //     let isDynamicEnd = await contractInstance.isDynamicGameEnded();
  //     // sethybridGameEnd(isHybridEnd);
  //     const tilesNumber = globalStage < 1 ? 1 : isDynamicEnd == false ? globalStage : randomNumber !== undefined ? globalStage + 1 : globalStage;
  //     settilenumber(tilesNumber);
  //   }
  // }

  const handleShoww = () => {
    myNfts &&
      Object.keys(myNfts).map((i, index) => {
        {
          myNfts[i] && myNfts[i].length > 0 &&
            myNfts[i].map((data, key) => {
              if (safeSides[Number(data.stage._hex) - 1] !== undefined) {
                if (
                  (data.lastJumpSide == true &&
                    safeSides[Number(data.stage._hex) - 1].safeTile >= 50) ||
                  (data.lastJumpSide == false &&
                    safeSides[Number(data.stage._hex) - 1].safeTile < 50)
                ) {
                  data.stageStatus = true;
                } else {
                  data.stageStatus = false;
                }
              }
              // console.log(data, "daaaaaaaaaaaaaaaaaaaaaaaaaa")
              //     // debugger
            });
        }
      });
    setmyNftModalShoww(true);
    // buyBackCondition()
  };

  const handleMyNftModalClose = () => setmyNftModalShoww(false);
  // const handleSwitchSideModalClose = () => setpushBackState(false)

  const handleNftListClose = () => {
    setNftListShow(false);
  };

  const handleNftListShow = async () => {
    setNftListShow(true);
  };

  const handleShow = (stageNumber, side) => {
    if (stageNumber !== 0) {
      if (side === false) {
        const result = allUsers[stageNumber].filter(
          (obj) => obj.lastJumpSide == false
        );
        setAllNftList(result);
      }
      if (side === true) {
        const result = allUsers[stageNumber].filter(
          (obj) => obj.lastJumpSide == true
        );
        setAllNftList(result);
      }
    } else {
      setAllNftList(allUsers[stageNumber]);
    }
    setShow(true);
  };

  const handleRestartGameModal = () => {
    setRestartGameModal(true);
  };

  const closeRestartGameModal = () => {
    setRestartGameModal(false);
  };

  const nftCardModalClose = () => {
    setClickedNft([]);
    setNftCardModal(false);
  };

  const handleClose = () => setShow(false);

  const safeTilesForGame = () => {
    let array = [];
    for (let i = 0; i < contractData?.safetiles?.length; i++) {
      let data = {
        stage: i,
        safeTile: Number(contractData?.safetiles[i]),
      };
      array.push(data);
    }
    setSafeSide(array);
  };

  const availableNFts = async () => {
    if (contractData.nftIds.length > 0) {
      const isExistedArray = await isExistedPromise(contractData.nftIds);

      let array = [];
      contractData.nftData &&
        contractData.nftData.map((data) => {
          for (let i = 0; i < isExistedArray.length; i++) {
            if (
              isExistedArray[i].nftId == data.tokenId &&
              isExistedArray[i].status == true
            ) {
              data.playerId = isExistedArray[i].playerId;

              array.push(data);
            }
          }
        });
      setusersNft(array);
    }
  };
  const getPushBackAdvantage = async () => {
    let getPlayerIds = [];
    let playerItem = [];
    try {
      getPlayerIds = await contractData.contractInstance.getStagesData(
        contractData.globalStage
      );
      for (let i = 0; i < getPlayerIds.length; i++) {
        playerItem = await contractData.contractInstance.playerItem(
          getPlayerIds[i]
        );
        if (contractData.signerWallet === playerItem.userWalletAddress) {
          setuserStage(Number(playerItem.stage));
          //// debugger
        }
      }
    } catch (error) {
      // toast.error("error in push back advantage");
    }
    // console.log(Number(playerItem.stage) , playerItem.userWalletAddress , "pushBackkkkk")
  };

  // const calculateBuyBackIn = () => {
  //   if (contractData?.globalStage > 0) {
  //     if (contractData?.globalStage <= contractData.buyBackCurve.length) {
  //       return contractData.buyBackCurve[contractData?.globalStage - 1];
  //     } else if (contractData?.globalStage > contractData.buyBackCurve.length) {
  //       return contractData.buyBackCurve[contractData.buyBackCurve.length - 1];
  //     }
  //   }
  //   return 0;
  // };

  const isExistedPromise = async (idArray) => {
    return new Promise(async function (resolve1, reject1) {
      let promises = [];
      for (let i = 0; i < idArray?.length; i++) {
        promises.push(
          new Promise((resolve, reject) => {
            contractData.contractInstance
              ?.isExist(idArray[i].playerId)
              .then((res) => {
                resolve({
                  status: res,
                  playerId: idArray[i].playerId,
                  nftId: idArray[i].tokenId,
                  series: idArray[i].series,
                  ipfsAddress: idArray[i].ipfsAddress,
                  image: idArray[i].image,
                });
              })
              .catch((e) => {
                reject(e);
              });
          })
        );
      }
      Promise.all(promises).then((x) => {
        resolve1(x);
      });
    });
  };

  const participationInGame = async (direction, playerID) => {
    if (contractData?.contractInstance) {
      let participation =
        await contractData?.contractInstance.participateInGame(
          direction,
          playerID
        );

      return participation;
    }
  };

  const back = () => {
    if (stages >= 1) {
      setstop(true);
      setstages(stages - 1);
      setstair(stair - 1);
    } else {
      setstop(false);
    }
  };

  const forward = () => {
    setstop(true);
    if (stages < contractData.tilesNumber) {
      setstages(stages + 1);
      setstair(stair + 1);
    }
  };

  const leftOrRightMove = async (direction) => {
    if (contractData?.contractInstance) {
      try {
        setLoader(true);
        setleftRightModal(false);

        const side = direction == "left" ? false : true;
        let participation = await participationInGame(side, userNft.playerId);

        let participationSuccess = await participation.wait();
        if (participationSuccess) {
          setsinglePlayerId(userNft.playerId);
          side == false ? SetLeftMove(true) : SetRightMove(true);

          dispatch(getLatestStage(contractData.contractInstance));
          dispatch(getLatestData(contractData?.contractInstance));
        } else {
          toast.error("Transaction Failed ");
        }
      } catch (error) {
        setLoader(false);
        toast.error(
          error.error?.data
            ? error.error?.data.message.split(":")[1]
            : "transaction failed"
        );
      }
    }
  };

  const getImagesFromDb = async (usersData) => {
    // console.log("RESPONSE", usersData)

    return new Promise(async function (resolve1, reject1) {
      let promises = [];

      for (let i = 0; i < usersData?.length; i++) {
        if (
          usersData[i].userWalletAddress !==
          "0x0000000000000000000000000000000000000000"
        ) {
          promises.push(
            new Promise((resolve, reject) => {
              axios
                .post(
                  `${process.env.REACT_APP_SERVER_URL}/v1/series1/get/metadata`,
                  {
                    tokenId: Number(usersData[i].nftId._hex),
                    series:
                      usersData[i].nftSeriestype == 1
                        ? "seriesOne"
                        : "seriesTwo",
                  }
                )
                .then((res) => {
                  let imageUrl;
                  if (usersData[i].nftSeriestype == 1) {
                    imageUrl =
                      process.env.REACT_APP_IPFS_URL +
                      res.data.data.ipfsImage.replace(":/", "");
                  } else {
                    imageUrl = res.data.data.ipfsImage;
                  }
                  const name = res.data.data.name;
                  const series = usersData[i].nftSeriestype;
                  resolve({
                    ...usersData[i],
                    metaData: {
                      imageUrl,
                      name,
                      tokenId: Number(usersData[i].nftId._hex),
                      series,
                    },
                  });
                })
                .catch((e) => {
                  reject(e);
                });
            })
          );
        }
      }
      Promise.all(promises).then((x) => {
        resolve1(x);
      });
    });
  };

  const userDataForStage = async () => {
    try {
      if (contractData.stagesUserData.length > 0) {
        dispatch({
          type: LOADER_STATE_TRUE,
        });
        // setLoader(true);
        let allUsersArray = [];
        let userArray = [];
        let countArray = [];
        // array of ids

        let loopPromises = [];

        contractData.stagesUserData.map(async (data) => {
          let leftUsers = [];
          let rightUsers = [];

          let tempUserData = [];

          loopPromises.push(
            new Promise(async (resolve, reject) => {
              try {
                let usersData = await getUserStagesData(data.stageData);
                usersData = await getImagesFromDb(usersData);
                // allUsersArray[data.stageNumber] = ;
                if (usersData.length > 0) {
                  usersData.forEach((item) => {
                    if (item.userWalletAddress == contractData.signerWallet) {
                      tempUserData.push(item);
                    }
                  });
                  // userArray[data.stageNumber] = tempUserData;
                  if (data.stageNumber !== 0) {
                    leftUsers = usersData.filter(
                      (obj) => obj.lastJumpSide == false
                    );
                    rightUsers = usersData.filter(
                      (obj) => obj.lastJumpSide == true
                    );
                    // countArray[data.stageNumber] = ;
                  }
                }

                resolve({
                  stage: data.stageNumber,
                  usersData,
                  userArray: tempUserData,
                  countArray: {
                    rightUsers: rightUsers.length,
                    leftUsers: leftUsers.length,
                  },
                });
              } catch (e) {
                resolve({
                  stage: null,
                  usersData: null,
                  userArray: null,
                  countArray: { rightUsers: null, leftUsers: null },
                });
              }
            })
          );
        });
        let zeroNftsCheck = 0;
        Promise.all(loopPromises).then((x) => {
          
          x.map((data) => {
            countArray[data.stage] = data.countArray;
            data.usersData.sort((a) =>
              a.userWalletAddress == contractData?.signerWallet ? -1 : 1
            );
            allUsersArray[data.stage] = data.usersData;
            userArray[data.stage] = data.userArray;
            if(data.userArray.length > 0){
              zeroNftsCheck = zeroNftsCheck + 1;
            }
            
          });
          if (
            contractData?.isDynamicEnd == false &&
            userArray[contractData.globalStage].length > 0 &&
            contractData.gameInitializeDay !== 0 &&
            safeSides[contractData.globalStage - 1] !== undefined
          ) {
            const safeTile = safeSides[contractData.globalStage - 1].safeTile;
            let successNfts = false;
            userArray[contractData.globalStage].map((data, i) => {
              if (
                (data.lastJumpSide == false && safeTile < 50) ||
                (data.lastJumpSide == true && safeTile >= 50)
              ) {
                successNfts = true;
              }
            });
            if (successNfts === true) {
              setClaim(true);
            } else {
              // console.log("SET CLAIM FALSE>>>")
              setClaim(false);
            }
          } else {
            // console.log("CLAIM SET", false)
            setClaim(false);
          }
          setIsZeroNfts(zeroNftsCheck > 0 ? false : true);
          setCountedArray(countArray);
          // console.log(allUsersArray, "allUsersArray")
          setAllUsers(allUsersArray);
          setMyNfts(userArray);
          SetLeftMove(false);
          SetRightMove(false);
          dispatch({
            type: LOADER_STATE_FALSE,
          });
          setLoader(false);
          // dispatch(dataOfUsers(allUsersArray, userArray));
        });
      }
    } catch (e) {
      dispatch({
        type: LOADER_STATE_FALSE,
      });
    }
  };

  const getUserStagesData = async (playerIds) => {
    if (contractData.contractInstance) {
      setLoader(true);
      return new Promise(async function (resolve1, reject1) {
        let promises = [];

        for (let i = 0; i < playerIds.length; i++) {
          if (
            playerIds[i] !==
            "0x0000000000000000000000000000000000000000000000000000000000000000"
          ) {
            // TODO: Uncomment this line and comment the other
            // promises.push(await contractData.contractInstance.playerItem(playerIds[i]))

            promises.push(
              new Promise((resolve, reject) => {
                contractData.contractInstance
                  .playerItem(playerIds[i])
                  .then((res) => {
                    // res.playerId = playerIds[i]
                    resolve({ ...res, playerId: playerIds[i] });
                  })
                  .catch((e) => {
                    reject(e);
                  });
              })
            );
          }
        }
        Promise.all(promises).then((x) => {
          resolve1(x);
          setLoader(false);
        });
      });
    }
  };

  const handleSwitchSideModal = () => {
    setSwitchSideModal(true);
  };
  const handlePushBackState = () => {
    setpushBackState(true);
    closeSwitchSideModal();
    toast.info(
      "Please select your NFT first from which your want to use push back ability"
    );
  };

  const closeSwitchSideModal = () => {
    setClickedNft([]);
    setSwitchSideModal(false);
  };
  const closeCongratulationModel = () => {
    setCongratulationShow(!congratulationShow);
    setLoader(true);
    dispatch(getLatestStage(contractData?.contractInstance));
    dispatch(getLatestData(contractData?.contractInstance));
  };

  const handleLoader = (type) => {
    setLoader(type);
  };

  const conditionalModalHandling = (data) => {
    const stage = Number(data.stage._hex);
    setuserStage(stage);
    let elementFound = clickedNft.map((c) => c.playerId);
    elementFound = elementFound.indexOf(data.playerId);
    setpushBackdisabled(!data.isPushBack);
    if (
      stage === contractData?.globalStage &&
      stage !== 0 &&
      safeSides[contractData?.globalStage - 1]?.safeTile == undefined
    ) {
      if (
        pushBackState === false &&
        contractData.signerWallet == data.userWalletAddress
      ) {
        handleSwitchSideModal();
      }
      // debugger
      if (data.isPushBack === true && pushBackState === true) {
        if (clickedNft.length < 1) {
          // debugger
          if (contractData.signerWallet == data.userWalletAddress) {
            if (elementFound < 0) {
              setClickedNft([...clickedNft, data]);
            } else {
              let prevData = [...clickedNft];
              prevData.splice(elementFound, 1);
              setClickedNft([...prevData]);
            }
          } else {
            // debugger
            toast.error("Please select your NFT first");
          }
        } else if (clickedNft.length === 1) {
          if (contractData.signerWallet == data.userWalletAddress) {
            // debugger
            if (elementFound < 0 && stage !== contractData?.globalStage) {
              setClickedNft([...clickedNft, data]);
            } else {
              let prevData = [...clickedNft];
              prevData.splice(elementFound, 1);
              setClickedNft([...prevData]);
              // debugger
            }
          } else {
            // debugger
            toast.error("you cannot apply for push back here");
          }
        } else {
          if (contractData.signerWallet !== data.userWalletAddress) {
            if (elementFound < 0) {
              // debugger
              setClickedNft([...clickedNft, data]);
            } else {
              let prevData = [...clickedNft];
              prevData.splice(elementFound, 1);
              setClickedNft([...prevData]);
              // debugger
            }
          } else {
            // debugger
            toast.error("you can select only one NFT for push back");
            toast.info(
              "if you want to de-select your NFT de-select other NFT first  "
            );
          }
        }
      } else {
        // debugger
        if (contractData.signerWallet == data.userWalletAddress) {
          // debugger
          if (data.isPushBack === false && contractData.globalStage > 1) {
            // debugger
            // toast.error("you dont have the ability to push back")
            setpushBackdisabled(true);
            setClickedNft([...clickedNft, data]);
            handleSwitchSideModal();
          } else {
            // debugger
            setClickedNft([...clickedNft, data]);
            handleSwitchSideModal();
          }
        } else {
          // debugger
          // toast.error("Not your NFT !")
          // setNftCardModal(true);
          let array = [];
          array.push(data);
          setClickedNft(array);
        }
      }
    } else if (
      stage !== contractData?.globalStage &&
      stage !== 0 &&
      clickedNft.length > 0
    ) {
      // debugger
      if (
        clickedNft.length === 1 &&
        contractData.signerWallet !== data.userWalletAddress
      ) {
        // debugger
        setClickedNft([...clickedNft, data]);
      } else {
        if (
          elementFound < 0 &&
          contractData.signerWallet !== data.userWalletAddress
        ) {
          // debugger
          if (clickedNft.length < 3) {
            // debugger
            toast.error("you can select only 1 NFt for push back");
          } else {
            // debugger
            setClickedNft([...clickedNft, data]);
          }
        } else {
          if (contractData.signerWallet !== data.userWalletAddress) {
            let prevData = [...clickedNft];
            prevData.splice(elementFound, 1);
            setClickedNft([...prevData]);
            // debugger
          } else {
            toast.error("you cannot select your own NFT for push back");
          }
        }
      }
    } else {
      // debugger
      // if((stage !== contractData?.globalStage && stage !== 0) ){
      if (pushBackState === false) {
        // console.log(stage, "dataaaa")
        // debugger
        handleLeftRightModal(data);
      } else if (
        clickedNft.length < 2 &&
        stage !== contractData?.globalStage &&
        stage !== 0
      ) {
        // if(contractData.signerWallet !== data.userWalletAddress){
        // debugger
        handleLeftRightModal(data);
        // }
        // else{
        //   toast.error
        // }
      } else {
        if (clickedNft.length === 0) {
          // debugger
          toast.error("please select your nft to pushback");
        } else if (stage == 0) {
          // debugger
          toast.error("you cannot push back any NFT at stage 0");
        }
      }
      // }
      // else{
      //   toast.error('you cannot push back NFT at stage 0')
      // }
    }
  };

  const handleLeftRightModal = (data) => {
    if (contractData.signerWallet == data.userWalletAddress) {
      setstages(Number(data.stage));
      setsstages(Number(data.stage));
      if (
        contractData.isDynamicEnd === false &&
        claim === true &&
        Number(data.stage) === contractData.globalStage
      ) {
        setclaimDisable(true);
      } else {
        setleftRightModal(true);
      }
      setstop(false);
      setUserNft(data);
    } else {
      // debugger
      // toast.error("Not your NFT");
      // setNftCardModal(true);
      let array = [];
      array.push(data);
      setClickedNft(array);
    }
  };

  const pioneerPushBack = async () => {
    if (contractData.contractInstance) {
      try {
        //     // debugger
        setLoader(true);
        let pushBack = await contractData.contractInstance.pushBack(
          clickedNft[0].playerId,
          clickedNft[1].playerId
        );
        let pushBackSuccess = await pushBack.wait();
        //     // debugger
        if (pushBackSuccess) {
          //     // debugger
          setClickedNft([]);
          setpushBackState(false);
          // dispatch(getLatestStage(contractData.contractInstance))
          dispatch(getLatestData(contractData?.contractInstance));
        } else {
          toast.error("Transaction Failed ");
        }
      } catch (error) {
        //     // debugger
        setLoader(false);
        toast.error("Transaction Failed ");
      }
    }
  };

  const handleGameDataModal = (data) => {
    setGameDataModal(data);
  };

  const dataFunForTile = (arr, side) => {
    let returnData = [];
    let count = 0;
    arr.map((data) => {
      if (data.lastJumpSide == side && count < 3) {
        returnData.push(data);
        count++;
      }
    });
    return returnData;
  };

  // const treasuryBalanceDistribute = async () => {
  //   let _totalBalance = await contractData.contractInstance.treasuryBalance();
  //   return Number(_totalBalance)
  // }

  const claimButtonFunctionality = async (playerId) => {
    // calculateRewardPerPlayer();
    if (contractData?.contractInstance) {
      try {
        const tresauryBalance = Number(
          await contractData?.contractInstance.treasuryBalance()
        );
        if (tresauryBalance !== 0) {
          let claimReward =
            await contractData?.contractInstance.claimWinnerEther(playerId);
          setLoader(true);
          let successClaim = await claimReward.wait();
          if (successClaim) {
            // dispatch(getLatestStage(contractData.contractInstance))
            // dispatch(getLatestData(contractData?.contractInstance));
            setLoader(false);
            setclaimDisable(false);
            // contractData?.contractInstance.on("claimPlayerReward", (playerId, amount, event) => {
            //   setClaimAmount(Number(amount) / 1e18);
            //   console.log("**********Set Congratulation Show Amount***********");
            //   // console.log(Number(amount) / 1e18);
            // })
            setCongratulationShow(true);
          }
        } else {
          toast.error("Treasury Balance is 0");
        }
      } catch (e) {
        setLoader(false);
        toast.error(e.error?.data?.message.split(":")[1]);
      }
    }
  };

  const closeGameDataModal = () => {
    setGameEndModalHandle(false);
    setGameDataModal(false);
  };

  return (
    <div
      className={`game-banner-wraper ${stop ? "step" : ""} ${
        contractData.latestTimestampOfUsers > contractData?.gameEndDays
          ? "disable"
          : ""
      } ${current > 0 ? "stop" : ""}  ${StimeoutTrue ? "" : ""}`}
    >
      {/* {console.log(clickedNft, "hellooooooooooooo")} */}
      {loader === true || contractData?.loaderRedux === true ? <Loader /> : ""}
      <div className="connect-wallet">
        <div className="claim-btn">
          {contractData.signerWallet ? (
            <p>Total Game Reward : {contractData?.totalReward} ETH</p>
          ) : (
            <p>Please connect to MetaMask</p>
          )}

          {claimDisable == true ? (
            <button
              onClick={() => claimButtonFunctionality(userNft.playerId)}
              className="btn-connect claim"
            >
              Claim
            </button>
          ) : (
            ""
          )}
        </div>

        <div className="btn-wrapper">
          {/* {console.log(userStage, contractData.globalStage, "stageeeeeeeeeeeeeeeeee" )} */}
          {/* {contractData.signerWallet ? userStage === contractData.globalStage && contractData.globalStage > 1 ? <button onClick={() => handlePushBackState()} disabled={pushBackdisabled}  className="btn-connect">
          Pioneer 
          </button> : "" : ""} */}
           { contractData.signerWallet != "" ? (<button className="btn-connect" onClick={() => handleShoww()}>
            My NFTS
          </button>) : ""}  
          { contractData.signerWallet != "" ? (<button onClick={handleNftListShow} className="btn-connect">
            NFT List{" "}
          </button>) : "" }
          {contractData.signerWallet !== "" &&
          contractData?.ownerAddress == contractData?.signerWallet ? (
            <button onClick={handleRestartGameModal} className="btn-connect">
              Restart Game{" "}
            </button>
          ) : (
            ""
          )}
          <ConnectWallet />
        </div>
        <Timer safeSides={safeSides} />

        
      </div>
      {contractData?.reloadPopUp ? (
          <div className="timeout-error">
            <p>Your Game Data is out Dated. Please Reload your Game. </p>
            <button
              className="btn btn-white"
              onClick={() => {
                window.location.reload();
              }}
            >
              Reload
            </button>
          </div>
        ) : (
          ""
        )}
      <div className="bridge-background">
        {/* <Image src={backgroundGif} fluid /> */}
        <video width="320" height="240" autoPlay loop>
          <source src={backgroundVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="sun-image">
        <Image className="rotate" src={Sun} fluid />
      </div>
      <div
        // className={`stairs-image stage-${stair} ${contractData.startGameTime !== 0 && contractData.gameInitializeDay > 0 && contractData.isHybridEnd == false && stair > 7 ? "show" : ""}`}
        className={`stairs-image stage-12 ${
          contractData.startGameTime !== 0 &&
          contractData.gameInitializeDay > 0 &&
          contractData.isDynamicEnd == false &&
          stages >= contractData?.globalStage
            ? "show"
            : ""
        }`}
      >
        <Image src={Stairs} fluid />
      </div>
      <div className="stage-title">Stage {stages}</div>
      <div className="titles-wrapper">
        <div className="clouds slow-up-down">
          <img src={Clouds} alt="Clouds" className="img-fluid" />{" "}
        </div>
        {(() => {
          const arr = [];
          for (let i = contractData.tilesNumber + 1; i >= 2; i--) {
            arr.push(
              <div
                className={`tile-row ${sstages === i ? "stay" : ""} ${
                  current >= i ? "current" : ""
                } tile-row${i - stages}-image ${
                  i >= "15" && i - stages >= "15" ? "stage-large" : ""
                } ${stages - i > "12" ? "stage-small" : ""}`}
              >
                <div className="slow-up-down">
                  <div className="d-flex justify-content-between">
                    <div
                      className={`tile left-tile ${
                        safeSides[i - 2]?.safeTile !== undefined &&
                        safeSides[i - 2]?.safeTile >= 50
                          ? contractData.countdownLoader == false &&
                            gameDataModal == false
                            ? "drop-left-tile drop-left-tile-no-anim"
                            : contractData.countDownStageNumber != i - 1
                            ? "drop-left-tile"
                            : ""
                          : ""
                      }`}
                    >
                      <Image src={Tile12} fluid />

                      <div className="current-all-nfts d-flex align-items-end">
                        {countedArray[i - 1]?.leftUsers > 3 ? (
                          <button
                          className="more-button"
                          onClick={() => handleShow(i - 1, false)}
                        >
                          <span className="fa fa-bars"></span><span className="number">{countedArray[i - 1]?.leftUsers}</span>
                        </button>
                        ) : (
                          ""
                        )}
                        {/* {console.log(allUsers, "alalalalalalalalalalala")} */}
                        <div className="related-nft">
                          {allUsers[i - 1] &&
                            dataFunForTile(allUsers[i - 1], false).map(
                              (dataOfUser, key) => {
                                return dataOfUser.lastJumpSide == false ? (
                                  <>
                                    {/* {console.log(clickedNft.map((c) => c.playerId).indexOf(dataOfUser.playerId),pushBackState,"humazzzzzzzzzzzzzzzzzzzzleft")} */}

                                    <div
                                      key={key}
                                      className={`nft-fram  ${
                                        clickedNft
                                          ?.map((c) => c.playerId)
                                          .indexOf(dataOfUser.playerId) < 0 ||
                                        pushBackState == false
                                          ? ""
                                          : "active"
                                      }   ${
                                        singlePlayerId != "" &&
                                        singlePlayerId == dataOfUser.playerId
                                          ? ` ${
                                              LeftMove ? `left-move ` : ""
                                            }  ${
                                              RightMove ? `right-move ` : ""
                                            }`
                                          : ""
                                      } `}
                                    >
                                      {Number(dataOfUser.day) >=
                                      Math.floor(
                                        contractData?.gameInitializeDay
                                      ) ? (
                                        <div className="moved-icon">
                                          <span className="icon">
                                            {" "}
                                            <FontAwesomeIcon
                                              icon={faArrowAltCircleUp}
                                            />
                                          </span>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      {dataOfUser.lastJumpSide == false ? (
                                        <Image
                                          onClick={() =>
                                            conditionalModalHandling(dataOfUser)
                                          }
                                          src={
                                            dataOfUser.metaData.imageUrl
                                              ? dataOfUser.metaData.imageUrl
                                              : Nftfram
                                          }
                                          fluid
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  ""
                                );
                              }
                            )}
                        </div>
                      </div>
                    </div>
                    <div
                      className={`tile right-tile ${
                        safeSides[i - 2]?.safeTile < 50 &&
                        safeSides[i - 2]?.safeTile !== undefined
                          ? contractData.countdownLoader == false &&
                            gameDataModal == false
                            ? "drop-right-tile drop-right-tile-no-anim"
                            : contractData.countDownStageNumber != i - 1
                            ? "drop-right-tile"
                            : ""
                          : ""
                      }`}
                    >
                      <Image src={Tile11} fluid />
                      <div className="current-all-nfts d-flex align-items-end">
                        {countedArray[i - 1]?.rightUsers > 3 ? (
                          <button
                          className="more-button"
                          onClick={() => handleShow(i - 1, true)}
                        >
                          <span className="fa fa-bars"></span><span className="number">{countedArray[i - 1]?.rightUsers}</span>
                        </button>
                        ) : (
                          ""
                        )}
                        {/* {console.log(
                          allUsers,
                          "alaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa"
                        )} */}
                        <div className="related-nft">
                          {allUsers[i - 1] &&
                            dataFunForTile(allUsers[i - 1], true).map(
                              (dataOfUser, key) => {
                                return (
                                  <>
                                    {/* {console.log(
                                      clickedNft
                                        .map((c) => c.playerId)
                                        .indexOf(dataOfUser.playerId),
                                      pushBackState,
                                      "humazzzzzzzzzzzzzzzzzzzzright"
                                    )} */}
                                    <div
                                      key={key}
                                      className={`nft-fram  ${
                                        clickedNft
                                          .map((c) => c.playerId)
                                          .indexOf(dataOfUser.playerId) < 0 ||
                                        pushBackState == false
                                          ? ""
                                          : "active"
                                      } ${
                                        singlePlayerId != "" &&
                                        singlePlayerId == dataOfUser.playerId
                                          ? ` ${
                                              LeftMove ? `left-move ` : ""
                                            }  ${
                                              RightMove ? `right-move ` : ""
                                            }`
                                          : ""
                                      } `}
                                    >
                                      {Number(dataOfUser.day) >=
                                      Math.floor(
                                        contractData?.gameInitializeDay
                                      ) ? (
                                        <div className="moved-icon">
                                          <span className="icon">
                                            {" "}
                                            <FontAwesomeIcon
                                              icon={faArrowAltCircleUp}
                                            />
                                          </span>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      {dataOfUser.lastJumpSide == true ? (
                                        <Image
                                          onClick={() =>
                                            conditionalModalHandling(dataOfUser)
                                          }
                                          src={
                                            dataOfUser.metaData.imageUrl
                                              ? dataOfUser.metaData.imageUrl
                                              : Nftfram
                                          }
                                          fluid
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </>
                                );
                              }
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
          return arr;
        })()}

        {/* Tile 0 */}
        <div
          className={`tile-row ${sstages === 0 ? "stay" : ""} base ${
            current >= 0 ? "current" : ""
          } tile-row${1 - stages}-image ${
            stages - 1 > "12" ? "stage-small" : ""
          }`}
        >
          <div className="up-down">
            <div className="d-flex justify-content-around">
              <div className="tile ">
                <Image src={Tile11} fluid />
                <div className="current-all-nfts d-flex align-items-end">
                  {allUsers[0]?.length > 3 ? (
                    <button
                      className="more-button"
                      onClick={() => handleShow(0)}
                    >
                      <span className="fa fa-bars"></span>
                      <span className="number">{allUsers[0]?.length}</span>
                    </button>
                  ) : (
                    ""
                  )}
                  <div className="related-nft">
                    {allUsers[0] &&
                      allUsers[0].map((dataOfUser, key) => {
                        return key <= 2 ? (
                          <>
                            <div
                              key={key}
                              className={`nft-fram ${
                                singlePlayerId != "" &&
                                singlePlayerId == dataOfUser.playerId
                                  ? ` ${LeftMove ? `left-move ` : ""}  ${
                                      RightMove ? `right-move ` : ""
                                    }`
                                  : ""
                              } `}
                            >
                              {/* {dataOfUser.lastJumpSide == false ? ( */}
                              <Image
                                onClick={() =>
                                  conditionalModalHandling(dataOfUser)
                                }
                                src={
                                  dataOfUser.metaData.imageUrl
                                    ? dataOfUser.metaData.imageUrl
                                    : Nftfram
                                }
                                fluid
                              />
                              {/* ) : (
                                ""
                              )} */}
                            </div>
                          </>
                        ) : (
                          ""
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="move-btns">
        <div className="">
          <button className="back-btn " onClick={back}>
            <img src={BottomIcon} alt="Bottom Icon" className="img-fluid" />
          </button>
          <button className="forward-btn mb-3" onClick={forward}>
            <img src={TopIcon} alt="Top Icon" className="img-fluid"/>
          </button>
        </div>
      </div>
      {leftRightModal === true ? (
        <div className="time-modal">
          <button
            type="button"
            className="close"
            onClick={() => setleftRightModal(!leftRightModal)}
          >
            {/* <span aria-hidden="true">&times;</span> */}
          </button>
          <div className="custom-modal-header">Select Tile For Jump </div>
          <div className="custom-modal-content">
            <div className="arrow-wrapper d-flex align-items-center justify-content-between">
              {safeSides[Number(userNft.stage._hex)] !== undefined &&
              safeSides[Number(userNft.stage._hex)].safeTile >= 50 ? (
                <div className="arrow-icon right-icon"></div>
              ) : (
                <div
                  className="arrow-icon left-icon"
                  onClick={() => leftOrRightMove("left")}
                >
                  <img src={LeftIcon} alt="icon left" className="img-fluid" />
                </div>
              )}

              {safeSides[Number(userNft.stage._hex)] !== undefined &&
              safeSides[Number(userNft.stage._hex)].safeTile < 50 ? (
                <div className="arrow-icon right-icon"></div>
              ) : (
                <div
                  className="arrow-icon right-icon"
                  onClick={() => leftOrRightMove("right")}
                >
                  <img src={RightIcon} alt="icon right" className="img-fluid" />
                </div>
              )}
            </div>
            <button className="btn-connect">Stay</button>
          </div>
        </div>
      ) : (
        ""
      )}
      {contractData.signerWallet != "" &&
      contractData?.ownerAddress !== contractData?.signerWallet ? (
        (contractData.lastJumpTime != 0 &&
          contractData.latestTimestampOfUsers > contractData?.gameEndDays &&
          claim == false &&
          contractData.countdownLoader == false &&
          gameDataModal == false) ||
        (contractData?.isDynamicEnd === false &&
          contractData.gameInitializeDay !== 0 &&
          contractData.countdownLoader == false &&
          gameDataModal == false &&
          gameEndModalHandle == false &&
          safeSides[contractData.globalStage - 1]?.safeTile !== undefined &&
          claim === false) ? (
          <>
            <div className="game-over-bg"></div>
            <div className="time-modal">
              <div className="custom-modal-content pt-0">
                <h1 className="text-center my-5">Game Ended</h1>
              </div>
            </div>
          </>
        ) : (
          ""
        )
      ) : (
        ""
      )}
      {/* {
        (contractData.signerWallet != "" ?
          (contractData.startGameTime == 0 || contractData.gameInitializeDay < 0 ?
            <>
              <div className="game-over-bg"></div>
              <div className="time-modal">
                <div className="custom-modal-content pt-0">
                  <h2 className="text-center my-5">Game is not started yet</h2>
                </div>
              </div>
            </> : "") : "")
      } */}
      {contractData.countdownLoader === true &&
      contractData?.loaderRedux === false ? (
        // && ( contractData.lastJumpTime != 0 && contractData.latestTimestampOfUsers < contractData?.gameEndDays) ||
        // ( contractData.lastJumpTime != 0 && contractData.gameInitializeDay < contractData?.thresHoldTime)
        <CountdownModal
          gameDataModalShow={(data) => {
            handleGameDataModal(data);
          }}
          gameEndModalHandler={() => {
            setGameEndModalHandle(true);
          }}
        />
      ) : (
        ""
      )}

      {contractData.latestTimestampOfUsers > contractData?.gameEndDays ||
      (contractData?.isDynamicEnd === false &&
        contractData.gameInitializeDay !== 0 &&
        contractData.latestTimestampOfUsers >= 1) ? (
        ""
      ) : contractData?.remainingNftLoader === true ? (
        <>
          {console.log("REMAINIG NFT MODAL???????")}
          <RemainingNftModal allUsers={allUsers} safeSides={safeSides} />
        </>
      ) : (
        ""
      )}

      <MyNftModal
        show={myNftModal}
        handlePushBackState={() => handlePushBackState()}
        pushBackdisabled={pushBackdisabled}
        allUsers={allUsers}
        close={() => handleMyNftModalClose()}
        myNfts={myNfts}
        conditionalModalHandling={(data) => {
          conditionalModalHandling(data);
        }}
        lodaer={(type) => handleLoader(type)}
        // calculateBuyBackIn={calculateBuyBackIn}
        safeSides={safeSides}
        clickedNft={clickedNft}
        zeroNftCheck = {isZeroNfts}
      />
      <PlayerListModal
        show={show}
        close={() => handleClose()}
        allNftList={allNftList}
        conditionalModalHandling={(data) => {
          conditionalModalHandling(data);
        }}
      />
      <NftListModal
        show={nftListShow}
        close={() => handleNftListClose()}
        lodaer={(type) => handleLoader(type)}
        myNftData={usersNft}
        // calculateBuyBackIn={calculateBuyBackIn}
        availableNFts={availableNFts}
        safeSides={safeSides}
      />
      <SwitchSideModal
        show={switchSideModal}
        close={() => closeSwitchSideModal()}
        nftData={clickedNft}
        lodaer={(type) => handleLoader(type)}
        handlePushBackState={() => handlePushBackState()}
        pushBackdisabled={pushBackdisabled}
      />
      {/* winnerReward={winnerReward} */}
      <CongratulationModal
        show={congratulationShow}
        close={() => closeCongratulationModel()}
        congratulationShow={congratulationShow}
        claimAmount={claimAmount}
        lodaer={(type) => handleLoader(type)}
      />
      {/* <PushBackModal show={pushBackState} close ={() => handleSwitchSideModalClose()} />  */}
      <RestartGameModal
        show={restartGameModal}
        close={() => closeRestartGameModal()}
        lodaer={(type) => handleLoader(type)}
      />
      {/* {console.log(clickedNft, "clickedNftclickedNft")} */}
      <NftCardModal
        show={nftCardModal}
        close={() => {
          nftCardModalClose();
        }}
        nftData={clickedNft}
      />

      <GameDataModal
        show={gameDataModal}
        close={() => closeGameDataModal()}
        safeSides={safeSides}
        allUsers={allUsers}
        myNfts={myNfts}
        countedArray={countedArray}
        lodaer={(type) => handleLoader(type)}
      />
    </div>
  );
};

export default Banner;
