import { React, useState, useEffect } from "react";
import { Button, Modal, Image } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Nftfram from "../../assets/images/nft-fram.png";
import { getLatestData, getLatestStage } from "../../redux/connectWallet/action";

const PushBackNFTModal = (props) => {
    const contractData = useSelector((state) => state.contract);
    const [nftSelected, setnftSelected] = useState([]);
    const [noNft, setnoNft] = useState("")
    const [count, setCount] = useState([]);
    const [pioneerList, setpioneerList] = useState([]);

    let dispatch = useDispatch();

    // const currentSLot = (data) => {
    //     const slot = process.env.REACT_APP_TIMESLOT;
    //     let date = Math.floor(Date.now() / 1000);
    //     let x = Number(data.lastJumpTime._hex);
    //     let gameTime = date - Number(data.lastJumpTime._hex);
    //     let day = parseInt(gameTime / slot);
    //     // debugger
    //     return day;
    // }

    const incrementCount = (key, data) => {
        let prevData = [...count]
        if (prevData[key] === undefined) {
            prevData[key] = 1
            setCount([...prevData])
        }
        else {
            let countNew = 0;
            (Number(props.nftSelected[0]?.stage) == contractData.globalStage &&
                props.safeSides[contractData.globalStage - 1]?.safeTile == undefined)
                ? countNew = props.nftSelected[0]?.pushBackCount - 1 : countNew = props.nftSelected[0]?.pushBackCount
            if (countNew == Number(data.stage)) {
                // debugger
                if (prevData[key] < Number(data.stage)) {
                    prevData[key] = prevData[key] + 1
                    setCount([...prevData])
                }
                else {
                    // debugger
                    toast.error('you cannot push back NFT above its stages')
                }
            }
            else if (countNew < Number(data.stage)) {
                if (prevData[key] != countNew) {
                    prevData[key] = prevData[key] + 1
                    setCount([...prevData])
                }
                else {
                    toast.error('you cannot push back more than your available count')
                }
            }
            else {
                if (countNew > Number(data.stage)) {
                    if (prevData[key] != Number(data.stage)) {
                        prevData[key] = prevData[key] + 1
                        setCount([...prevData])
                    }
                    else {
                        toast.error('you cannot push back NFT above its stages')
                    }
                }
            }
            // else {
            //     debugger
            //     toast.error("you have less push back so select count accordingly")
            // }                     
        }
        // debugger
    };

    const decrementCount = (key) => {
        if (count[key] > 1 && count[key] !== undefined) {
            let prevData = [...count]
            prevData[key] = prevData[key] - 1
            setCount([...prevData])
        }
    };

    const handleNftSelection = (data) => {

        if (nftSelected.length < 1) {
            let itemIndex = nftSelected.map((c) => c.nftId);
            itemIndex = itemIndex.indexOf(data.nftId);
            if (itemIndex < 0) {
                // debugger
                setnftSelected([...nftSelected, data]);
            }
            else {
                // debugger
                let prevData = [...nftSelected];
                prevData.splice(itemIndex, 1);
                setnftSelected([...prevData]);
            }
        }
        else {
            // toast.error('you cannot select more than one NFT to pushBack')
        }
    }

    const PushBackTranscation = async (data, key) => {
        if (contractData.contractInstance) {
            try {
                let pushBack;
                if (count[key] !== undefined) {
                    if (props.nftSelected[0]?.pushBackCount != 0) {
                        props.lodaer(true);
                        pushBack = await contractData.contractInstance.pushBack(props.nftSelected[0]?.playerId, data, count[key]);

                        let pushBackSuccess = await pushBack.wait();
                        if (pushBackSuccess) {
                            // setnftSelected([])
                            dispatch(getLatestStage(contractData.contractInstance))
                            dispatch(getLatestData(contractData?.contractInstance));
                            toast.success("You push Back the NFT successfully")
                            setCount([])
                            // checkOfNftExist()
                            props.close()
                        } else {
                            props.lodaer(false);
                            toast.error("Transaction Failed");
                        }
                    }
                    else {
                        toast.error('You dont have any push back to use')
                    }
                }
                else {
                    toast.error('Please select how many stages you want to push back')
                }

            } catch (error) {
                props.lodaer(false);
                toast.error(error.error?.data ? error.error?.data.message.split(":")[1] : "Your Transaction has been failed");
                // toast.error("error in Push Back Transaction ");
            }
        }
    }
    const checkPioneer = (playerId) => {
        if (pioneerList.length > 0) {
            for (let i = 0; i < pioneerList.length; i++) {
                if (playerId == pioneerList[i]) {
                    return true; 
                }
            }
        }
        return false; 
    };

    // const checkPioneer = (playerId) => {
    //     debugger
    //     // try {
    //         if(pioneerList.length > 0){
    //     debugger
    //             let flag = false;
    //             for(let i=0; i < pioneerList.length; i++){
    //                 if(playerId == pioneerList[i]){
    //                     // debugger
    //                     flag =  true;
    //                     break;
    //                 }
    //                 else {   
    //                     // debugger

    //                     flag = false;
    //                 }
    //             }
    //             // debugger
    //             return flag;
    //         }
    //         else {
    //             return false
    //         }
    //     }
    //     // } catch (error) {
    //     //     // debugger
    //     //     return false;
    //     // }

    // // }
    const fetchData = async () => {
        // console.log(contractData.globalStage)
        if(contractData.globalStage > 0){
            let globalStageAtMoment = parseInt(contractData.globalStage)
            let isPioneerCheck = await contractData.contractInstance.getAllPioneer(globalStageAtMoment);
            setpioneerList(isPioneerCheck)
        }        
    }

    useEffect (() => {       
        fetchData()
    },[contractData.globalStage])

    const handlePushBackClose = () => {
        setCount([])
        props.close();
    }

    useEffect(() => {
        if (props.allUsers[Number(props.nftSelected[0]?.stage) - 1]) {
            let temp = 0;
            props.allUsers[Number(props.nftSelected[0]?.stage) - 1].map((items) => {
                if (
                    props.safeSides[Number(props.nftSelected[0]?.stage) - 2]?.safeTile !== undefined
                    && ((props.safeSides[Number(props.nftSelected[0]?.stage) - 2]?.safeTile >= 50
                        && items.lastJumpSide == true)
                        || (props.safeSides[Number(props.nftSelected[0]?.stage) - 2]?.safeTile < 50
                            && items.lastJumpSide == false))
                    && contractData.signerWallet !== items.userWalletAddress
                    && (checkPioneer(items.playerId) == false)
                    )
                     {
                    temp = temp + 1
                }
                ;
            })
            temp === 0 ?
                setnoNft("No Nft available for pushback") : setnoNft("")
        }
    }, [props.allUsers[Number(props.nftSelected[0]?.stage) - 1]])


    return (
        <>
        {/* {console.log("<<<<<<<<<<<<<<<<<<<<<<<<============================>>>>>>>>>>>>>>>>>>>>>>>")}
        {console.log("<<<<<<<<<<<<<<<<<<<<<<<<============================>>>>>>>>>>>>>>>>>>>>>>>")}
        {console.log("<<<<<<<<<<<<<<<<<<<<<<<<============================>>>>>>>>>>>>>>>>>>>>>>>")}
        {console.log(pioneerList, "pioneeeeeeeeeeerrrr list")}
        {console.log("<<<<<<<<<<<<<<<<<<<<<<<<============================>>>>>>>>>>>>>>>>>>>>>>>")}
        {console.log("<<<<<<<<<<<<<<<<<<<<<<<<============================>>>>>>>>>>>>>>>>>>>>>>>")}
        {console.log("<<<<<<<<<<<<<<<<<<<<<<<<============================>>>>>>>>>>>>>>>>>>>>>>>")} */}
            <Modal className="mynft-list restart nfts-data"
                show={props.show}
                cancel={() => handlePushBackClose()}
                size="lg"
                centered>
                <Modal.Header className="justify-content-center">
                    <Modal.Title>Players list</Modal.Title>
                    <button type="button" className="close" onClick={() => handlePushBackClose()}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
                <Modal.Body >
                    <div className="bulk-nft-list push-back">
                        {props.allUsers[Number(props.nftSelected[0]?.stage) - 1] !== undefined
                            && props.allUsers[Number(props.nftSelected[0]?.stage) - 1].map((data, key) => {
        // {console.log("<<<<<<<<<<<<<<<<<<<<<<<<============================>>>>>>>>>>>>>>>>>>>>>>>")}
        // {console.log("<<<<<<<<<<<<<<<<<<<<<<<<============================>>>>>>>>>>>>>>>>>>>>>>>")}
        // {console.log("<<<<<<<<<<<<<<<<<<<<<<<<============================>>>>>>>>>>>>>>>>>>>>>>>")}
        // {console.log(checkPioneer(data.playerId), "checkPioneercheckPioneer")}
        // {console.log(data.playerId)}
        // {console.log("<<<<<<<<<<<<<<<<<<<<<<<<============================>>>>>>>>>>>>>>>>>>>>>>>")}
        // {console.log("<<<<<<<<<<<<<<<<<<<<<<<<============================>>>>>>>>>>>>>>>>>>>>>>>")}
        // {console.log("<<<<<<<<<<<<<<<<<<<<<<<<============================>>>>>>>>>>>>>>>>>>>>>>>")}
                                if (
                                    (props.safeSides[Number(props.nftSelected[0]?.stage) - 2]?.safeTile !== undefined)
                                    && ((props.safeSides[Number(props.nftSelected[0]?.stage) - 2]?.safeTile >= 50
                                        && data.lastJumpSide == true)
                                        || (props.safeSides[Number(props.nftSelected[0]?.stage) - 2]?.safeTile < 50
                                            && data.lastJumpSide == false))
                                    && (contractData.signerWallet !== data.userWalletAddress)  
                                    && (checkPioneer(data.playerId) == false)
                                ) {
                                    return (
                                        <div key={key} className={`item `}>

                                            <div
                                                className={`nft-content-wrapper d-flex align-items-center ${data.stageStatus == false ||
                                                    data.isDrop === false
                                                    ? "game-over"
                                                    : `nft-item `
                                                    }`}
                                                onClick={() => handleNftSelection(data)}
                                            >
                                                <div className="nft-img">
                                                    <Image
                                                        src={
                                                            data.metaData.imageUrl
                                                                ? data.metaData.imageUrl
                                                                : Nftfram
                                                        }
                                                        fluid
                                                    />
                                                </div>

                                                <div className="nft-content">
                                                    <h3>NFT ID : {Number(data.nftId)}</h3>
                                                    <h3>Stage : {Number(data.stage)} </h3>
                                                    <div className="product-count">
                                                        <button className="button-count no-active" onClick={() => decrementCount(key)} >-</button>
                                                        <input type="text" readOnly className="number-product" value={count[key]} />
                                                        <button className="button-count" onClick={() => incrementCount(key, data)}>+</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <button
                                            className="restart"
                                            onClick={() =>
                                                PushBackTranscation(
                                                    data.playerId,
                                                    key
                                                )
                                            }
                                        >
                                            Push Back
                                        </button>
                                            
                                        </div>
                                    )

                                }
                                // }
                            })}
                        <div className="nft-label no-data" style={{ color: 'white' }}>
                            <h2>

                                {""} {noNft}{" "}

                            </h2>
                        </div>


                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default PushBackNFTModal