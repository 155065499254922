import { React, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const NftCardModal = (props) => {
    let dispatch = useDispatch();
    const contractData = useSelector((state) => state.contract);
    // console.log("Data>>>>>>>>.", props.nftData, props.nftData.length > 0)

    return (
        <>
            {props.nftData.length < 2 && props.nftData.map((data) => {
                return (
                    <div>
                        {contractData.signerWallet !== data.userWalletAddress ?
                         <Modal
                         className="mynft-list nft-card"
                         show={true}
                         cancel={props.close}
                         size="lg"
                         centered

                     >
                         <Modal.Header className="justify-content-center">
                             <Modal.Title>Nft Card </Modal.Title>
                             <button type="button" className="close" onClick={props.close}>
                                 <span aria-hidden="true">&times;</span>
                             </button>
                         </Modal.Header>
                         <Modal.Body>
                             <div className="nft-card">
                                 <div className="nft-image">
                                     <img src={data.metaData?.imageUrl} alt="NFT" className="img-fluid" />
                                 </div>
                                 <div className="nft-detail">
                                     <ul>
                                         <li><label>Owner : </label> <span>{data.userWalletAddress}</span></li>
                                         <li><label>Name : </label> <span>{data?.metaData?.name}</span></li>
                                     </ul>

                                 </div>
                             </div>

                         </Modal.Body>
                         <Modal.Footer>
                             <Button className="btn-connect" onClick={() => {
                                 window.open(`${process.env.REACT_APP_OPENSEA_URL}/${data?.metaData?.series == 2 ?
                                     process.env.REACT_APP_NFT_SERIES2_ADDRESS :
                                     process.env.REACT_APP_NFT_SERIES1_ADDRESS}/${data?.metaData?.tokenId}`)
                             }} >
                                 See Nft
                             </Button>
                         </Modal.Footer>
                     </Modal> : "" }
                       
                    </div>

                )
            })}
        </>
    );
};

export default NftCardModal;